var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mt-2", attrs: { rounded: "xl", ripple: false } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c("v-col", { attrs: { cols: "2" } }, [
                _c("div", [_vm._v("TÌM KIẾM LỊCH TÀU")]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    staticClass: "pr-2",
                    attrs: {
                      label: "Nhập mã chuyến tàu",
                      "prepend-inner-icon": "mdi-magnify",
                      type: "text",
                      dense: "",
                      solo: "",
                      flat: "",
                      outlined: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.typedSearch,
                      callback: function ($$v) {
                        _vm.typedSearch = $$v
                      },
                      expression: "typedSearch",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "v-dialog",
                    {
                      ref: "dialog",
                      attrs: { persistent: "", "max-width": "670px" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pr-2",
                                      attrs: {
                                        value: _vm.dateRangeText,
                                        label: "Ngày đi - Ngày đến",
                                        "hide-details": "",
                                        "append-icon": "mdi-calendar-month",
                                        readonly: "",
                                        dense: "",
                                        outlined: "",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.modal,
                        callback: function ($$v) {
                          _vm.modal = $$v
                        },
                        expression: "modal",
                      },
                    },
                    [
                      _c(
                        "v-date-picker",
                        {
                          attrs: {
                            range: "",
                            landscape: "",
                            "full-width": "",
                            allowedDates: _vm.allowedDates,
                          },
                          on: { change: _vm.onChangeDates },
                          model: {
                            value: _vm.dates,
                            callback: function ($$v) {
                              _vm.dates = $$v
                            },
                            expression: "dates",
                          },
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.modal = false
                                },
                              },
                            },
                            [_vm._v(" Hủy ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.onSelectEtdEta },
                            },
                            [_vm._v(" Chọn ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "v-badge",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        value: _vm.advancedFilterCount > 0,
                        color: "error",
                        content: `${_vm.advancedFilterCount}`,
                        overlap: "",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { height: "40px" },
                          attrs: {
                            width: "100%",
                            color: "primary",
                            outlined: !_vm.isExpandFilter,
                            ripple: false,
                          },
                          on: {
                            click: function ($event) {
                              _vm.isExpandFilter = !_vm.isExpandFilter
                            },
                          },
                        },
                        [
                          _c("v-icon", {
                            attrs: { left: "" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.isExpandFilter
                                  ? "mdi-chevron-double-up"
                                  : "mdi-chevron-double-down"
                              ),
                            },
                          }),
                          _vm._v(" Tìm kiếm nâng cao "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-expand-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isExpandFilter,
                    expression: "isExpandFilter",
                  },
                ],
              },
              [
                _c("v-row", { attrs: { "no-gutters": "" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "mt-6 pb-2",
                      staticStyle: { "font-size": "12px" },
                    },
                    [
                      _c("v-icon", [_vm._v(" mdi-filter-variant ")]),
                      _vm._v(" BỘ LỌC NÂNG CAO "),
                    ],
                    1
                  ),
                ]),
                _c(
                  "v-row",
                  {
                    staticClass: "mb-4",
                    attrs: { "no-gutters": "", align: "center" },
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("VesselPicker", {
                          staticClass: "pr-2",
                          attrs: {
                            value: _vm.selectedVesselCode,
                            label: "Chọn tàu",
                            clearable: "",
                          },
                          on: { change: _vm.onSelectVessel },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("PortPicker", {
                          staticClass: "pr-2",
                          attrs: {
                            value: _vm.selectedPolCode,
                            label: "Chọn POL",
                            clearable: "",
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                          },
                          on: { change: _vm.onSelectPOL },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "4" } },
                      [
                        _c("PortPicker", {
                          staticClass: "pr-2",
                          attrs: {
                            value: _vm.selectedPodCode,
                            label: "Chọn POD",
                            clearable: "",
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                          },
                          on: { change: _vm.onSelectPOD },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }