var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "py-1 px-2", attrs: { rounded: "lg" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "p",
            { staticClass: "overline" },
            [_vm._v(" Filters "), _c("v-icon", [_vm._v("mdi-filter-variant")])],
            1
          ),
          _c(
            "v-form",
            {
              attrs: { onSubmit: "return false;" },
              on: { submit: _vm.searchSchedule },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Enter voyage code",
                      "prepend-inner-icon": "mdi-magnify",
                      type: "text",
                      solo: "",
                      flat: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.typedSearch,
                      callback: function ($$v) {
                        _vm.typedSearch = $$v
                      },
                      expression: "typedSearch",
                    },
                  }),
                  _c("VesselPicker", {
                    attrs: {
                      value: _vm.selectedVesselCode,
                      label: "Choose vessel",
                      solo: "",
                      flat: "",
                      clearable: "",
                      "hide-details": "",
                    },
                    on: { change: _vm.onSelectVessel },
                  }),
                  _c("PortPicker", {
                    attrs: {
                      value: _vm.selectedPolCode,
                      solo: "",
                      label: "Choose POL",
                      clearable: "",
                      "hide-details": "",
                    },
                    on: { change: _vm.onSelectPOL },
                  }),
                  _c("PortPicker", {
                    attrs: {
                      value: _vm.selectedPodCode,
                      solo: "",
                      label: "Choose POD",
                      clearable: "",
                      "hide-details": "",
                    },
                    on: { change: _vm.onSelectPOD },
                  }),
                  _c(
                    "v-dialog",
                    {
                      ref: "dialog",
                      attrs: { persistent: "", "max-width": "670px" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        value: _vm.dateRangeText,
                                        label: "Ngày đi - Ngày đến",
                                        "hide-details": "",
                                        "prepend-inner-icon":
                                          "mdi-calendar-month",
                                        readonly: "",
                                        solo: "",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.modal,
                        callback: function ($$v) {
                          _vm.modal = $$v
                        },
                        expression: "modal",
                      },
                    },
                    [
                      _c(
                        "v-date-picker",
                        {
                          attrs: {
                            range: "",
                            landscape: !_vm.$vuetify.breakpoint.mobile,
                            "full-width": "",
                            allowedDates: _vm.allowedDates,
                          },
                          on: { change: _vm.onChangeDates },
                          model: {
                            value: _vm.dates,
                            callback: function ($$v) {
                              _vm.dates = $$v
                            },
                            expression: "dates",
                          },
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.modal = false
                                },
                              },
                            },
                            [_vm._v(" Hủy ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.onSelectEtdEta },
                            },
                            [_vm._v(" Chọn ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-10",
                      attrs: {
                        width: "100%",
                        color: "primary",
                        large: "",
                        disabled: _vm.isLoading,
                      },
                      on: { click: _vm.searchSchedule },
                    },
                    [
                      _c("v-icon", [_vm._v(" mdi-magnify ")]),
                      _vm._v(" Search "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }