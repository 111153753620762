<template>
  <div class="pa-0">
    <div style="min-height: 500px">
      <v-card
        class="mt-3"
        color="background"
        elevation="0"
        rounded="xl"
      >
        <v-card-text style="font-size: 11px">
          <v-row no-gutters>
            <v-col
              cols="2"
              class="font-weight-bold"
            >
              TÀU
            </v-col>
            <v-col
              cols="1"
              class="font-weight-bold"
            >
              CHUYẾN TÀU
            </v-col>
            <v-col
              cols="2"
              class="text-center font-weight-bold"
            >
              POL
            </v-col>
            <v-col
              cols="3"
              class="text-center font-weight-bold"
            >
              POD
            </v-col>
            <v-col
              cols="2"
              class="text-center font-weight-bold"
            >
              NGÀY ĐI - NGÀY ĐẾN
            </v-col>
            <v-col
              cols="2"
              class="text-center font-weight-bold"
            >
              TRẠNG THÁI TÀU
            </v-col>
            <!-- <v-col cols="2" class="font-weight-bold"> </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
      <template v-if="!isLoading">
        <VesselScheduleListItem
          v-for="item in vesselScheduleList"
          :key="item.code"
          :item="item"
          :to="{ path: '/app/shipping/request-a-quote/' + item.code }"
        />
      </template>

      <template v-if="isLoading">
        <v-card
          v-for="(item, i) in 5"
          :key="i"
          class="mt-2"
          rounded="xl"
        >
          <v-skeleton-loader
            :boilerplate="false"
            type="list-item-two-line"
          />
        </v-card>
      </template>
      <NoData v-if="!isLoading && vesselScheduleList.length === 0" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapMutations, mapState } from 'vuex';
import VesselScheduleListItem from './VesselScheduleListItem.vue';
import NoData from '@/components/shared/local/base/NoData.vue';
export default {
  components: { VesselScheduleListItem, NoData },
  data: () => ({
    selectedOrder: null,
  }),
  computed: {
    ...mapState('vsl/vesselSchedule', [
      'vesselScheduleList',
      'vesselScheduleListLoading',
    ]),
    isLoading: {
      get() {
        return this.vesselScheduleListLoading;
      },
      set(value) {
        this.SET_VESSEL_SCHEDULE_LIST_LOADING(value);
      },
    },
  },
  async created() {
    try {
      this.isLoading = true;
      let { pol_code, pod_code, from_etd, to_eta } = this.$route.query;

      if (!from_etd) {
        from_etd = dayjs().format('YYYY-MM-DD');
      }
      await this.$router
        .push({
          path: 'request-a-quote',
          query: { pol_code, pod_code, from_etd, to_eta },
        })
        .catch(() => {});
      const queryParams = {
        etdEta: [from_etd, to_eta],
        polCode: pol_code,
        podCode: pod_code,
      };
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS(queryParams);

      await this.fetchVesselScheduleList();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapMutations('vsl/vesselSchedule', [
      'SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS',
      'SET_VESSEL_SCHEDULE_LIST_LOADING',
    ]),
    ...mapActions('vsl/vesselSchedule', ['fetchVesselScheduleList']),
  },
};
</script>

<style></style>
