<template>
  <v-card
    class="py-1 px-2"
    rounded="lg"
  >
    <v-card-text>
      <p class="overline">
        Filters

        <v-icon>mdi-filter-variant</v-icon>
      </p>
      <v-form
        @submit="searchSchedule"
        onSubmit="return false;"
      >
        <v-row align="center">
          <v-text-field
            v-model="typedSearch"
            label="Enter voyage code"
            prepend-inner-icon="mdi-magnify"
            type="text"
            solo
            flat
            hide-details
          />
          <VesselPicker
            :value="selectedVesselCode"
            @change="onSelectVessel"
            label="Choose vessel"
            solo
            flat
            clearable
            hide-details
          />
          <PortPicker
            :value="selectedPolCode"
            @change="onSelectPOL"
            solo
            label="Choose POL"
            clearable
            hide-details
          />

          <PortPicker
            :value="selectedPodCode"
            @change="onSelectPOD"
            solo
            label="Choose POD"
            clearable
            hide-details
          />
          <v-dialog
            ref="dialog"
            v-model="modal"
            persistent
            max-width="670px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateRangeText"
                label="Ngày đi - Ngày đến"
                hide-details
                prepend-inner-icon="mdi-calendar-month"
                readonly
                solo
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              @change="onChangeDates"
              range
              :landscape="!$vuetify.breakpoint.mobile"
              full-width
              :allowedDates="allowedDates"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Hủy
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="onSelectEtdEta"
              >
                Chọn
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-btn
            class="px-10"
            width="100%"
            color="primary"
            large
            :disabled="isLoading"
            @click="searchSchedule"
          >
            <v-icon> mdi-magnify </v-icon>
            Search
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapMutations, mapState } from 'vuex';
import PortPicker from '@/components/shared/local/vsl/PortPicker.vue';
import VesselPicker from '@/components/shared/local/vsl/VesselPicker.vue';
import { debounce, pluck } from 'rxjs/operators';
import { timer } from 'rxjs';

export default {
  components: { PortPicker, VesselPicker },
  data: () => ({
    dates: [dayjs().format('YYYY-MM-DD')],
    modal: false,
    isExpandFilter: false,
    typedSearch: '',
    selectedVesselCode: null,
    selectedEtdEta: [],
    selectedPolCode: null,
    selectedPodCode: null,
  }),
  computed: {
    ...mapState('vsl/vesselSchedule', {
      queryParams: 'vesselScheduleListQueryParams',
      vesselScheduleListLoading: 'vesselScheduleListLoading',
    }),
    isLoading: {
      get() {
        return this.vesselScheduleListLoading;
      },
      set(value) {
        this.SET_VESSEL_SCHEDULE_LIST_LOADING(value);
      },
    },
    dateRangeText() {
      const formated = this.selectedEtdEta.map((date) => {
        return dayjs(date).format('DD.MM.YYYY');
      });
      return formated.join(' - ');
    },
    advancedFilterCount() {
      const filters = {
        selectedVesselCode: this.selectedVesselCode,
        selectedPolCode: this.selectedPolCode,
        selectedPodCode: this.selectedPodCode,
      };
      console.log('[LOG] : filters', filters);
      const count = Object.keys(filters).filter(
        (x) => filters[x] != undefined
      ).length;
      console.log('[LOG] : count', count);
      return count;
    },
  },
  created() {
    let { search, vessel_code, pol_code, pod_code, from_etd, to_eta } =
      this.$route.query;
    if (to_eta) {
      this.selectedEtdEta = [from_etd, to_eta];
    } else {
      this.selectedEtdEta = [from_etd];
    }
    this.typedSearch = search;
    this.selectedVesselCode = vessel_code;
    this.selectedPolCode = pol_code;
    this.selectedPodCode = pod_code;

    this.$watchAsObservable('typedSearch')
      .pipe(
        pluck('newValue'),
        debounce(() => {
          this.isLoading = true;
          return timer(500);
        })
      )
      .subscribe(async (value) => {
        await this.$router
          .push({
            query: {
              ...this.$route.query,
              search: value,
            },
          })
          .catch(() => {});

        this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
          search: value,
        });
        this.searchSchedule();
      });
  },
  methods: {
    ...mapMutations('vsl/vesselSchedule', [
      'SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS',
      'SET_VESSEL_SCHEDULE_LIST_LOADING',
    ]),
    ...mapActions('vsl/vesselSchedule', ['fetchVesselScheduleList']),
    allowedDates(value) {
      return dayjs(value) > dayjs().subtract(1, 'day');
    },
    async searchSchedule() {
      try {
        this.isLoading = true;
        await this.fetchVesselScheduleList();
      } finally {
        this.isLoading = false;
      }
    },
    async onSelectVessel(item) {
      await this.$router
        .push({
          query: {
            ...this.$route.query,
            vessel_code: item?.code,
          },
        })
        .catch(() => {});
      this.selectedVesselCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ vesselCode: item?.code });
      this.searchSchedule();
    },
    async onSelectPOL(item) {
      await this.$router
        .push({
          query: {
            ...this.$route.query,
            pol_code: item?.code,
          },
        })
        .catch(() => {});
      this.selectedPolCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ polCode: item?.code });
      this.searchSchedule();
    },
    async onSelectPOD(item) {
      await this.$router
        .push({
          query: {
            ...this.$route.query,
            pod_code: item?.code,
          },
        })
        .catch(() => {});
      this.selectedPodCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ podCode: item?.code });
      this.searchSchedule();
    },
    onChangeDates(value) {
      this.dates = value;
    },
    async onSelectEtdEta() {
      this.modal = false;
      if (this.dates.length > 1) {
        if (dayjs(this.dates[0]).isAfter(dayjs(this.dates[1]))) {
          this.dates = [this.dates[1], this.dates[0]];
        }
      }
      this.selectedEtdEta = this.dates;
      await this.$router
        .push({
          query: {
            ...this.$route.query,
            from_etd: this.selectedEtdEta[0]
              ? this.selectedEtdEta[0]
              : undefined,
            to_etd: this.selectedEtdEta[1] ? this.selectedEtdEta[1] : undefined,
          },
        })
        .catch(() => {});
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        etdEta: this.selectedEtdEta,
      });
      this.searchSchedule();
    },
  },
};
</script>

<style></style>
