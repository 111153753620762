var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pa-0" }, [
    _c(
      "div",
      { staticStyle: { "min-height": "500px" } },
      [
        _c(
          "v-card",
          {
            staticClass: "mt-3",
            attrs: { color: "background", elevation: "0", rounded: "xl" },
          },
          [
            _c(
              "v-card-text",
              { staticStyle: { "font-size": "11px" } },
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "font-weight-bold", attrs: { cols: "2" } },
                      [_vm._v(" TÀU ")]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "font-weight-bold", attrs: { cols: "1" } },
                      [_vm._v(" CHUYẾN TÀU ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-center font-weight-bold",
                        attrs: { cols: "2" },
                      },
                      [_vm._v(" POL ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-center font-weight-bold",
                        attrs: { cols: "3" },
                      },
                      [_vm._v(" POD ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-center font-weight-bold",
                        attrs: { cols: "2" },
                      },
                      [_vm._v(" NGÀY ĐI - NGÀY ĐẾN ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-center font-weight-bold",
                        attrs: { cols: "2" },
                      },
                      [_vm._v(" TRẠNG THÁI TÀU ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        !_vm.isLoading
          ? _vm._l(_vm.vesselScheduleList, function (item) {
              return _c("VesselScheduleListItem", {
                key: item.code,
                attrs: {
                  item: item,
                  to: { path: "/app/shipping/request-a-quote/" + item.code },
                },
              })
            })
          : _vm._e(),
        _vm.isLoading
          ? _vm._l(5, function (item, i) {
              return _c(
                "v-card",
                { key: i, staticClass: "mt-2", attrs: { rounded: "xl" } },
                [
                  _c("v-skeleton-loader", {
                    attrs: { boilerplate: false, type: "list-item-two-line" },
                  }),
                ],
                1
              )
            })
          : _vm._e(),
        !_vm.isLoading && _vm.vesselScheduleList.length === 0
          ? _c("NoData")
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }